var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('header-area',{attrs:{"color-type":1}}),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"size":50,"indeterminate":"","color":"grey"}})],1):_c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-63",attrs:{"justify":"center","align-content":"end"}},[_c('h3',{staticClass:"header"},[_vm._v(" ユーザー情報修正 ")])]),_c('v-row',{staticClass:"mt-16",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-sei"},[_vm._v(" 姓（漢字） ")]),_c('label',{staticClass:"label-back mr-mei"},[_vm._v(" 名（漢字） ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('v-col',{staticClass:"pa-0 textbox-half-length",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"姓(漢字)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox-half",attrs:{"type":"text","error-messages":errors},model:{value:(_vm.userProfileType.lastName),callback:function ($$v) {_vm.$set(_vm.userProfileType, "lastName", $$v)},expression:"userProfileType.lastName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0 textbox-half-length",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"名(漢字)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox-half",attrs:{"type":"text","error-messages":errors},model:{value:(_vm.userProfileType.firstName),callback:function ($$v) {_vm.$set(_vm.userProfileType, "firstName", $$v)},expression:"userProfileType.firstName"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-sei"},[_vm._v(" 姓（カナ） ")]),_c('label',{staticClass:"label-back mr-mei"},[_vm._v(" 名（カナ） ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('v-col',{staticClass:"pa-0 textbox-half-length",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"姓(カナ)","rules":"required|kana"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox-half",attrs:{"type":"text","error-messages":errors},model:{value:(_vm.userProfileType.lastNameKana),callback:function ($$v) {_vm.$set(_vm.userProfileType, "lastNameKana", $$v)},expression:"userProfileType.lastNameKana"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0 textbox-half-length",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"名(カナ)","rules":"required|kana"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox-half",attrs:{"type":"text","error-messages":errors},model:{value:(_vm.userProfileType.firstNameKana),callback:function ($$v) {_vm.$set(_vm.userProfileType, "firstNameKana", $$v)},expression:"userProfileType.firstNameKana"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-nickname"},[_vm._v(" ニックネーム ")])]),_c('v-row',{attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"text-detail nickname-detail"},[_vm._v(" ※ミラーで表示する際に使用します ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"ニックネーム","rules":"required|nicknameFormat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"type":"text","error-messages":errors},model:{value:(_vm.userProfileType.nickname),callback:function ($$v) {_vm.$set(_vm.userProfileType, "nickname", $$v)},expression:"userProfileType.nickname"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-8",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-birthday"},[_vm._v(" 生年月日 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"年","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-year",attrs:{"items":_vm.yearType,"error-messages":errors},on:{"change":_vm.resetDay},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})]}}],null,true)}),_c('span',{staticClass:"mt-5 ml-2 mr-2"},[_vm._v(" / ")]),_c('validation-provider',{attrs:{"name":"月","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-month",attrs:{"items":_vm.monthType,"error-messages":errors},on:{"change":_vm.resetDay},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})]}}],null,true)}),_c('span',{staticClass:"mt-5 ml-2 mr-2"},[_vm._v(" / ")]),_c('validation-provider',{attrs:{"name":"日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-day",attrs:{"items":_vm.dayType,"error-messages":errors},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}})]}}],null,true)})],1),_c('v-row',{staticClass:"form-height",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-height"},[_vm._v(" 性別 ")])]),_c('v-row',{attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"text-detail gender-detail"},[_vm._v(" ※BMI取得に使用します ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"性別","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"textbox",attrs:{"items":_vm.genderType,"error-messages":errors},model:{value:(_vm.userProfileType.gender),callback:function ($$v) {_vm.$set(_vm.userProfileType, "gender", $$v)},expression:"userProfileType.gender"}})]}}],null,true)})],1),_c('v-row',{staticClass:"form-height",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-blood"},[_vm._v(" 血液型 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"血液型","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"textbox",attrs:{"items":_vm.bloodType,"error-messages":errors},model:{value:(_vm.userProfileType.bloodType),callback:function ($$v) {_vm.$set(_vm.userProfileType, "bloodType", $$v)},expression:"userProfileType.bloodType"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-height"},[_vm._v(" 身長 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"身長","rules":"required|height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"textbox",attrs:{"error-messages":errors,"items":_vm.height},model:{value:(_vm.userProfileType.height),callback:function ($$v) {_vm.$set(_vm.userProfileType, "height", $$v)},expression:"userProfileType.height"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-account"},[_vm._v(" 家族に自分の情報を共有しますか ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{staticClass:"my-info",attrs:{"name":"自分の情報","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"row":"","mandatory":""},model:{value:(_vm.userProfileType.isPublishing),callback:function ($$v) {_vm.$set(_vm.userProfileType, "isPublishing", $$v)},expression:"userProfileType.isPublishing"}},[_c('v-radio',{staticClass:"font-weight-bold",attrs:{"label":"共有","color":"black","value":"true"}}),_c('v-radio',{staticClass:"font-weight-bold",attrs:{"label":"共有しない","color":"black","value":"false"}})],1)]}}],null,true)}),_c('div',{staticClass:"pt-5"},[_c('v-btn',{staticClass:"btn-link",on:{"click":_vm.openModal}},[_vm._v(" 画面イメージはこちら ▶ ")])],1)],1),_c('v-row',{staticClass:"mt-15",attrs:{"justify":"center","align-content":"center"}},[_c('v-btn',{staticClass:"btn mb-4",attrs:{"disabled":invalid,"loading":_vm.isSaving},on:{"click":function($event){return _vm.Save()}}},[_vm._v(" 保存 ")])],1),_c('v-row',{attrs:{"justify":"center","align-content":"end"}},[_c('v-btn',{staticClass:"btn mb-8",on:{"click":function($event){return _vm.back()}}},[_vm._v(" 戻る ")])],1)],1)]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContent),expression:"showContent"}],staticClass:"overlay"},[_c('div',{staticClass:"content"},[_c('modal-vue'),_c('v-row',{staticClass:"pa-6",attrs:{"justify":"center","align-content":"center"}},[_c('button',{staticClass:"btn",on:{"click":_vm.closeModal}},[_vm._v(" 閉じる ")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }