import { render, staticRenderFns } from "./HeaderArea.vue?vue&type=template&id=4b0e321e&scoped=true&"
import script from "./HeaderArea.vue?vue&type=script&lang=ts&"
export * from "./HeaderArea.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderArea.vue?vue&type=style&index=0&id=4b0e321e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b0e321e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VIcon,VMain,VRow})
