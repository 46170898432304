var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('header-area',{attrs:{"color-type":1}}),_c('v-container',[_c('v-row',{staticClass:"mt-63",attrs:{"justify":"center","align-content":"end"}},[_c('h3',{staticClass:"header"},[_vm._v(" ユーザー管理 ")])]),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"size":50,"indeterminate":"","color":"grey"}})],1):_c('div',[_c('v-row',{staticClass:"vertical bottom  mt-8",attrs:{"justify":"center","align-content":"center"}},_vm._l((_vm.registerArray),function(ref,i){
var nickname = ref.nickname;
var lastName = ref.lastName;
var firstName = ref.firstName;
var email = ref.email;
return _c('v-col',{key:i,staticClass:"registers mt-2"},[_c('v-col',[_vm._v(" "+_vm._s(nickname)+" ")]),_c('div',{staticClass:"line",on:{"click":function($event){return _vm.userinfo(nickname, lastName, firstName, email)}}},[_c('div',{staticClass:"frame-text"},[_vm._v(" 詳細を見る ＞ ")])])],1)}),1),_c('v-row',{staticClass:"vertical bottom",attrs:{"justify":"center","align-content":"center"}},_vm._l((_vm.waitArray),function(ref,i){
var nickname = ref.nickname;
return _c('v-col',{key:i,staticClass:"waitregisters mt-2"},[_c('v-col',{staticClass:"transparent-text"},[_vm._v(" "+_vm._s(nickname)+" ")]),_c('div',{staticClass:"line"},[_c('div',{staticClass:"frame-text mr-3"},[_vm._v(" 登録待ち ")])])],1)}),1),_c('v-row',{staticClass:"vertical bottom",attrs:{"justify":"center","align-content":"center"}},_vm._l((_vm.overArray),function(ref,i){
var userName = ref.userName;
var nickname = ref.nickname;
var email = ref.email;
return _c('v-col',{key:i,staticClass:"overregisters mt-2"},[_c('v-col',[_vm._v(" "+_vm._s(nickname)+" ")]),_c('div',{staticClass:"line",on:{"click":function($event){return _vm.reissueUser(userName, nickname, email)}}},[_c('div',{staticClass:"frame-text"},[_vm._v(" 有効期限切れ ＞ ")])])],1)}),1),(!_vm.checkMaxUser())?_c('v-row',{attrs:{"justify":"center","align-content":"center"}},[_c('v-col',{staticClass:"Unregistered"},[_c('v-col',{staticClass:"transparent-text"},[_vm._v(" 未登録 ")]),_c('div',{staticClass:"line",on:{"click":_vm.adduser}},[_c('div',{staticClass:"frame-text mr-3"},[_vm._v(" ＋ 追加 ")])])],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-77",attrs:{"justify":"center","align-content":"end"}},[_c('v-btn',{staticClass:"backbtn",on:{"click":_vm.back}},[_vm._v(" 戻る ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }