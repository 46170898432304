var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"bg-color"},[_c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-3",attrs:{"justify":"center","align-content":"center"}},[_c('span',{staticClass:"header"},[_vm._v(" ユーザー情報を入力してください ")])]),_c('v-row',{staticClass:"mt-16",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label"},[_vm._v(" 姓(漢字) ")]),_c('label',{staticClass:"necessary mr-sei label"},[_vm._v(" ※必須 ")]),_c('label',{staticClass:"label"},[_vm._v(" 名(漢字) ")]),_c('label',{staticClass:"necessary mr-mei label"},[_vm._v(" ※必須 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('v-col',{staticClass:"pa-0 textbox-half-length",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"姓(漢字)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox-half",attrs:{"type":"text","placeholder":"山田","error-messages":errors},model:{value:(_vm.registProfile.userLastName),callback:function ($$v) {_vm.$set(_vm.registProfile, "userLastName", $$v)},expression:"registProfile.userLastName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0 textbox-half-length",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"名(漢字)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox-half",attrs:{"type":"text","placeholder":"太郎","error-messages":errors},model:{value:(_vm.registProfile.userFirstName),callback:function ($$v) {_vm.$set(_vm.registProfile, "userFirstName", $$v)},expression:"registProfile.userFirstName"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"form-height",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label"},[_vm._v(" 姓(カナ) ")]),_c('label',{staticClass:"necessary mr-sei label"},[_vm._v(" ※必須 ")]),_c('label',{staticClass:"label"},[_vm._v(" 名(カナ) ")]),_c('label',{staticClass:"necessary mr-mei label"},[_vm._v(" ※必須 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('v-col',{staticClass:"pa-0 textbox-half-length",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"姓(カナ)","rules":"required|kana"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox-half",attrs:{"type":"text","placeholder":"ヤマダ","error-messages":errors},model:{value:(_vm.registProfile.userLastNameKana),callback:function ($$v) {_vm.$set(_vm.registProfile, "userLastNameKana", $$v)},expression:"registProfile.userLastNameKana"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pa-0 textbox-half-length",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"名(カナ)","rules":"required|kana"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox-half",attrs:{"type":"text","placeholder":"タロウ","error-messages":errors},model:{value:(_vm.registProfile.userFirstNameKana),callback:function ($$v) {_vm.$set(_vm.registProfile, "userFirstNameKana", $$v)},expression:"registProfile.userFirstNameKana"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"form-height",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-nickname"},[_vm._v(" ニックネーム "),_c('label',{staticClass:"necessary"},[_vm._v(" ※必須 ")])])]),_c('v-row',{attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"text-detail mr-nickname-detail"},[_vm._v(" ※ミラーで表示する際に使用します ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"ニックネーム","rules":"required|nicknameFormat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"type":"text","placeholder":"taro","error-messages":errors},model:{value:(_vm.registProfile.nickname),callback:function ($$v) {_vm.$set(_vm.registProfile, "nickname", $$v)},expression:"registProfile.nickname"}})]}}],null,true)})],1),_c('v-row',{staticClass:"form-height",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-birthday"},[_vm._v(" 生年月日 (半角数字) "),_c('label',{staticClass:"necessary"},[_vm._v(" ※必須 ")])])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"年","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-year",attrs:{"items":_vm.yearType,"error-messages":errors},on:{"change":_vm.resetDay},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})]}}],null,true)}),_c('span',{staticClass:"mt-5 ml-2 mr-2"},[_vm._v(" / ")]),_c('validation-provider',{attrs:{"name":"月","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-month",attrs:{"items":_vm.monthType,"error-messages":errors},on:{"change":_vm.resetDay},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})]}}],null,true)}),_c('span',{staticClass:"mt-5 ml-2 mr-2"},[_vm._v(" / ")]),_c('validation-provider',{attrs:{"name":"日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-day",attrs:{"items":_vm.dayType,"error-messages":errors},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}})]}}],null,true)})],1),_c('v-row',{staticClass:"form-height",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-height"},[_vm._v(" 性別 "),_c('label',{staticClass:"necessary"},[_vm._v(" ※必須 ")])])]),_c('v-row',{attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"text-detail gender-detail"},[_vm._v(" ※BMI取得に使用します ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"性別","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"textbox",attrs:{"items":_vm.genderList,"placeholder":"男","error-messages":errors},model:{value:(_vm.registProfile.gender),callback:function ($$v) {_vm.$set(_vm.registProfile, "gender", $$v)},expression:"registProfile.gender"}})]}}],null,true)})],1),_c('v-row',{staticClass:"form-height",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-blood-type"},[_vm._v(" 血液型 "),_c('label',{staticClass:"necessary"},[_vm._v(" ※必須 ")])])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"血液型","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"textbox",attrs:{"items":_vm.bloodTypeList,"placeholder":"O","error-messages":errors},model:{value:(_vm.registProfile.bloodType),callback:function ($$v) {_vm.$set(_vm.registProfile, "bloodType", $$v)},expression:"registProfile.bloodType"}})]}}],null,true)})],1),_c('v-row',{staticClass:"form-height",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-height"},[_vm._v(" 身長 "),_c('label',{staticClass:"necessary"},[_vm._v(" ※必須 ")])])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"身長","rules":"required|height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"textbox",attrs:{"items":_vm.height,"placeholder":"170cm","error-messages":errors},model:{value:(_vm.registProfile.height),callback:function ($$v) {_vm.$set(_vm.registProfile, "height", $$v)},expression:"registProfile.height"}})]}}],null,true)})],1),_c('v-row',{staticClass:"form-height",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-family"},[_vm._v(" 家族に自分の情報を共有しますか "),_c('label',{staticClass:"necessary"},[_vm._v(" ※必須 ")])])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"自分の情報","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"my-info",attrs:{"error-messages":errors,"row":"","mandatory":""},model:{value:(_vm.registProfile.privacy),callback:function ($$v) {_vm.$set(_vm.registProfile, "privacy", $$v)},expression:"registProfile.privacy"}},[_c('v-radio',{staticClass:"font-weight-bold",attrs:{"label":"共有","color":"black","value":"true"}}),_c('v-radio',{staticClass:"font-weight-bold",attrs:{"label":"共有しない","color":"black","value":"false"}})],1)]}}],null,true)}),_c('div',{staticClass:"pt-5"},[_c('v-btn',{staticClass:"btn-link",on:{"click":_vm.openModal}},[_vm._v(" 画面イメージはこちら ▶ ")])],1)],1),_c('v-row',{staticClass:"form-height",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-pin"},[_vm._v(" 4桁暗証番号 "),_c('label',{staticClass:"necessary"},[_vm._v(" ※必須 ")])])]),_c('v-row',{attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"text-detail mr-mirror"},[_vm._v(" ※ミラーログイン時に使用します ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{ref:"pin",attrs:{"name":"4桁暗証番号","rules":"required|pinFormat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"id":"passwordPin","maxlength":"4","type":"number","placeholder":"0000","error-messages":errors},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==69){ return null; }$event.preventDefault();}},model:{value:(_vm.registProfile.pin),callback:function ($$v) {_vm.$set(_vm.registProfile, "pin", $$v)},expression:"registProfile.pin"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-15",attrs:{"justify":"center","align-content":"center"}},[_c('v-btn',{staticClass:"btn",attrs:{"disabled":invalid,"loading":_vm.isLoading},on:{"click":function($event){return _vm.Next()}}},[_vm._v(" 入力内容確認 ")])],1)],1)]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContent),expression:"showContent"}],staticClass:"overlay"},[_c('div',{staticClass:"content"},[_c('modal-vue'),_c('v-row',{staticClass:"pa-6",attrs:{"justify":"center","align-content":"center"}},[_c('button',{staticClass:"btn",on:{"click":_vm.closeModal}},[_vm._v(" 閉じる ")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }