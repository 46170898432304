var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"bg-color"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-56",attrs:{"justify":"center","align-content":"end"}},[_c('span',{staticClass:"header"},[_vm._v(" 住所を入力してください ")])]),_c('v-row',{staticClass:"mt-9",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-postal-code"},[_vm._v(" 郵便番号 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"郵便番号","rules":"required|postalCodeFormat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"type":"text","placeholder":"0000000","error-messages":errors},on:{"input":function($event){return _vm.getAddress(_vm.admin.zipCode)}},model:{value:(_vm.admin.zipCode),callback:function ($$v) {_vm.$set(_vm.admin, "zipCode", $$v)},expression:"admin.zipCode"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-address"},[_vm._v(" 都道府県・市区町村・番地 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"都道府県・市区町村・番地","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"type":"text","placeholder":"東京都○○区○○町0-0-0","error-messages":errors},model:{value:(_vm.admin.address1),callback:function ($$v) {_vm.$set(_vm.admin, "address1", $$v)},expression:"admin.address1"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-building"},[_vm._v(" 建物名・号室 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"建物名・号室"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"type":"text","placeholder":"○○ハイツ 000号室","error-messages":errors},model:{value:(_vm.admin.address2),callback:function ($$v) {_vm.$set(_vm.admin, "address2", $$v)},expression:"admin.address2"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-74",attrs:{"justify":"center","align-content":"end"}},[_c('v-btn',{staticClass:"btn",attrs:{"disabled":invalid,"loading":_vm.isLoading},on:{"click":function($event){return _vm.Next()}}},[_vm._v(" 次へ ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }