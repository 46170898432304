var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('header-area',{attrs:{"color-type":1}}),_c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-63",attrs:{"justify":"center","align-content":"end"}},[_c('h3',{staticClass:"header"},[_vm._v(" パスワード設定 ")])]),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"size":50,"indeterminate":"","color":"grey"}})],1):_vm._e(),_c('v-row',{staticClass:"mt-16",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label password"},[_vm._v(" 現行のパスワード ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{ref:"nowPassword",attrs:{"name":"現行のパスワード","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"placeholder":"入力してください","error-messages":errors},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label password"},[_vm._v(" 新しいパスワード ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{ref:"newPassword",attrs:{"name":"新しいパスワード","rules":"passwordFormat","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"placeholder":"入力してください","error-messages":errors},on:{"click:append":function($event){_vm.show2 = !_vm.show2},"input":function($event){return _vm.passChange()}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label re-password"},[_vm._v(" 新しいパスワード（再入力） ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"新しいパスワード（再入力）","rules":"confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"append-icon":_vm.show3 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show3 ? 'text' : 'password',"placeholder":"入力してください","error-messages":errors},on:{"click:append":function($event){_vm.show3 = !_vm.show3},"input":function($event){return _vm.passChange()}},model:{value:(_vm.rePassword),callback:function ($$v) {_vm.rePassword=$$v},expression:"rePassword"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-number"},[_vm._v(" 4桁暗証番号 ")])]),_c('v-row',{staticClass:"mt-3",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"text-detail mr-mirror"},[_vm._v(" ※ミラーログイン時に使用します ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{ref:"pin",attrs:{"name":"4桁暗証番号","rules":"required|pinFormat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox pin",attrs:{"id":"passwordPin","maxlength":"4","append-icon":_vm.show4 ? 'mdi-eye' : 'mdi-eye-off',"type":"number","error-messages":errors},on:{"click:append":function($event){_vm.show4 = !_vm.show4},"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==69){ return null; }$event.preventDefault();}},model:{value:(_vm.userProfileType.pin),callback:function ($$v) {_vm.$set(_vm.userProfileType, "pin", $$v)},expression:"userProfileType.pin"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-15",attrs:{"justify":"center","align-content":"center"}},[_c('v-btn',{staticClass:"btn mb-4",attrs:{"disabled":invalid || _vm.passCheckFlag,"loading":_vm.isSaving},on:{"click":function($event){return _vm.Save()}}},[_vm._v(" 保存 ")])],1),_c('v-row',{attrs:{"justify":"center","align-content":"end"}},[_c('v-btn',{staticClass:"btn mb-8",on:{"click":function($event){return _vm.back()}}},[_vm._v(" 戻る ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }