var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"bg-color"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-56",attrs:{"justify":"center","align-content":"end"}},[_c('span',{staticClass:"header"},[_vm._v(" パスワードを登録してください ")])]),_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-user-id"},[_vm._v(" メールアドレス ")])]),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"rules":"required","name":"メールアドレス"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"type":"text","placeholder":"test@example.co.jp","error-messages":errors,"readonly":""},model:{value:(_vm.registProfile.userId),callback:function ($$v) {_vm.$set(_vm.registProfile, "userId", $$v)},expression:"registProfile.userId"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-pass"},[_vm._v(" パスワード ")])]),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"パスワード","rules":"required|passwordFormat","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"placeholder":"入力してください","append-icon":_vm.pwShowFlag1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.pwShowFlag1 ? 'text' : 'password',"error-messages":errors},on:{"click:append":function($event){_vm.pwShowFlag1 = !_vm.pwShowFlag1}},model:{value:(_vm.registProfile.password),callback:function ($$v) {_vm.$set(_vm.registProfile, "password", $$v)},expression:"registProfile.password"}}),_c('div',{staticClass:"passwordLabel",class:{ passwordLabelMargin: errors.length !== 0 }},[_c('label',{staticClass:"password-detail"},[_vm._v(" ※英大文字、英小文字、数字、記号すべて1文字以上含む8文字以上 ")]),_c('label',{staticClass:"password-detail"},[_vm._v(" （すべて半角）で設定してください ")])])]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label mr-re-pass"},[_vm._v(" パスワード（再入力） ")])]),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"パスワード（再入力）","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"placeholder":"入力してください","append-icon":_vm.pwShowFlag2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.pwShowFlag2 ? 'text' : 'password',"error-messages":errors},on:{"click:append":function($event){_vm.pwShowFlag2 = !_vm.pwShowFlag2}},model:{value:(_vm.rePassword),callback:function ($$v) {_vm.rePassword=$$v},expression:"rePassword"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-74",attrs:{"justify":"center","align-content":"end"}},[_c('v-btn',{staticClass:"btn",attrs:{"disabled":invalid,"loading":_vm.isLoading},on:{"click":function($event){return _vm.Next()}}},[_vm._v(" 次へ ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }