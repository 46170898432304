var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('header-area',{attrs:{"color-type":1}}),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"size":50,"indeterminate":"","color":"grey"}})],1):_c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-63",attrs:{"justify":"center","align-content":"end"}},[_c('h3',{staticClass:"header"},[_vm._v(" ミラー情報修正 ")])]),_c('v-row',{staticClass:"mt-16",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label post"},[_vm._v(" 郵便番号 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"郵便番号","rules":"required|postalCodeFormat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"type":"text","error-messages":errors},on:{"input":function($event){return _vm.getAddress(_vm.admin.zipCode)}},model:{value:(_vm.admin.zipCode),callback:function ($$v) {_vm.$set(_vm.admin, "zipCode", $$v)},expression:"admin.zipCode"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label address1"},[_vm._v(" 都道府県・市区町村 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"都道府県・市区町村","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"type":"text","error-messages":errors},model:{value:(_vm.admin.address1),callback:function ($$v) {_vm.$set(_vm.admin, "address1", $$v)},expression:"admin.address1"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label address2"},[_vm._v(" 建物名・号室 ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"建物名・号室"}},[_c('v-text-field',{staticClass:"textbox",attrs:{"type":"text"},model:{value:(_vm.admin.address2),callback:function ($$v) {_vm.$set(_vm.admin, "address2", $$v)},expression:"admin.address2"}})],1)],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"label smid"},[_vm._v(" SM機器ID ")])]),_c('v-row',{attrs:{"justify":"center","align-content":"center"}},[_c('label',{staticClass:"text-detail smid-detail"},[_vm._v(" ※鏡に貼り付けしてある機器IDを入力してください ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{ref:"mirrorId",attrs:{"name":"SM機器ID","rules":"required|smIdFormat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"type":"text","error-messages":errors},model:{value:(_vm.admin.mirrorId),callback:function ($$v) {_vm.$set(_vm.admin, "mirrorId", $$v)},expression:"admin.mirrorId"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-15",attrs:{"justify":"center","align-content":"center"}},[_c('v-btn',{staticClass:"btn mb-4",attrs:{"disabled":invalid,"loading":_vm.isSaving},on:{"click":function($event){return _vm.Save()}}},[_vm._v(" 保存 ")])],1),_c('v-row',{attrs:{"justify":"center","align-content":"end"}},[_c('v-btn',{staticClass:"btn mb-8",on:{"click":function($event){return _vm.back()}}},[_vm._v(" 戻る ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }