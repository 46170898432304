var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"bg-color"},[_c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-56",attrs:{"justify":"center","align-content":"end"}},[_c('span',{staticClass:"header"},[_vm._v(" 内容確認 ")])]),_c('v-row',{staticClass:"mt-16",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"ミラー機器ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"label":"ミラー機器ID","error-messages":errors,"readonly":""},model:{value:(_vm.admin.mirrorId),callback:function ($$v) {_vm.$set(_vm.admin, "mirrorId", $$v)},expression:"admin.mirrorId"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"メールアドレス","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"label":"メールアドレス","error-messages":errors,"readonly":""},model:{value:(_vm.registProfile.userId),callback:function ($$v) {_vm.$set(_vm.registProfile, "userId", $$v)},expression:"registProfile.userId"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"パスワード","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"label":"パスワード","error-messages":errors,"type":"password","readonly":""},model:{value:(_vm.registProfile.password),callback:function ($$v) {_vm.$set(_vm.registProfile, "password", $$v)},expression:"registProfile.password"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"住所","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{key:_vm.autoGrowHack,staticClass:"text-area",attrs:{"value":_vm.getFullAddress,"label":"住所","rows":_vm.addressRow,"error-messages":errors,"auto-grow":"","readonly":""}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"名前（漢字）","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"value":_vm.getFullName,"label":"名前（漢字）","error-messages":errors,"readonly":""}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"名前（カナ）","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"value":_vm.getFullkanaName,"label":"名前（カナ）","error-messages":errors,"readonly":""}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"ニックネーム","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"label":"ニックネーム","error-messages":errors,"readonly":""},model:{value:(_vm.registProfile.nickname),callback:function ($$v) {_vm.$set(_vm.registProfile, "nickname", $$v)},expression:"registProfile.nickname"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"生年月日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"label":"生年月日","error-messages":errors,"readonly":""},model:{value:(_vm.registProfile.birthday),callback:function ($$v) {_vm.$set(_vm.registProfile, "birthday", $$v)},expression:"registProfile.birthday"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"性別","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"value":_vm.getGenderText,"label":"性別","error-messages":errors,"readonly":""}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"血液型","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"value":_vm.getBloodTypeText,"label":"血液型","error-messages":errors,"readonly":""}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"身長","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"label":"身長","error-messages":errors,"readonly":""},model:{value:(_vm.registProfile.height),callback:function ($$v) {_vm.$set(_vm.registProfile, "height", $$v)},expression:"registProfile.height"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"家族に自分の情報を共有しますか","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"value":_vm.getprivacyText,"label":"家族に自分の情報を共有しますか","error-messages":errors,"readonly":""}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center","align-content":"center"}},[_c('validation-provider',{attrs:{"name":"4桁暗証番号","rules":"required|pinFormat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"textbox",attrs:{"type":"password","label":"4桁暗証番号","error-messages":errors,"readonly":""},model:{value:(_vm.registProfile.pin),callback:function ($$v) {_vm.$set(_vm.registProfile, "pin", $$v)},expression:"registProfile.pin"}})]}}],null,true)})],1),_c('v-row',{staticClass:"mt-15",attrs:{"justify":"center","align-content":"center"}},[_c('v-btn',{staticClass:"btn",attrs:{"disabled":invalid,"loading":_vm.isLoading},on:{"click":function($event){return _vm.Next()}}},[_vm._v(" 入力した内容で申し込む ")])],1),_c('v-row',{staticClass:"mt-7",attrs:{"justify":"center","align-content":"center"}},[_c('v-btn',{staticClass:"btn",attrs:{"disabled":invalid,"loading":_vm.isEditing},on:{"click":function($event){return _vm.Modify()}}},[_vm._v(" 修正 ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }